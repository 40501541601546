<template>
  <div class="flex flex-wrap items-center gap-2 w-150">
    <div
      v-for="chip in selectableChips"
      :key="`chip-${chip.label}-${chip.value}`"
      class="px-3 py-1.5 border text-sm font-normal rounded-full bg-white cursor-pointer duration-200 ease-linear"
      :class="{ 'border-bb-brand-purple': chip.selected }"
      @click="selectChip(chip)"
    >
      <div class="flex items-center gap-2">
        <p>{{ chip.label }}</p>
        <div class="flex items-center gap-1 bg-bb-purple-10 rounded-full px-2 py-0.5">
          <slot name="chip-icon"></slot>
          <p class="text-xs text-bb-text-headers">
            <span
              v-if="metric"
              class="capitalize"
              >{{ metric }}: {{ chip.value }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectableChips',
  props: {
    chips: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    metric: {
      type: String,
      default: '',
    },
    selectedChips: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectableChips() {
      return this.chips.map(chip => ({
        ...chip,
        selected: this.selectedChips.some(
          selectedChip => selectedChip.value === chip.value && selectedChip.label === chip.label,
        ),
      }))
    },
  },
  methods: {
    selectChip(chip) {
      this.$emit('select-chip', chip)
    },
  },
}
</script>

<style scoped lang="scss"></style>
